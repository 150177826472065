import Axios from 'axios';
import localStorageService from './localStorageService';
import setNotification from './setNotification';
import logOut from '@/utils/logOut';
import store from '@/store';
import {MessageBox} from 'element-ui';
import {SET_LOCALE, GET_NOTIFICATION_COUNT} from '@/api';
import {isFrontendVersion, isFrontendVersionOld} from '@/utils/regExps';
import {SET_LOCALE as SET_LOCALE_ACTION} from '@/store/actionsName';
import {getCommonCaptionByName, getLocaleName} from '@/i18n/utils';
import I18N from '@/i18n';
import router from '@/router';
import {checkMobileViewTokenInterceptors} from '@/utils/checkMobileViewToken';

const elkAxios = Axios.create({
  baseURL: '/api/',
  timeout: 120 * 1000
});
const ALERT_CONFIRM_ACTION = 'confirm';

elkAxios.CancelToken = Axios.CancelToken;
elkAxios.isCancel = Axios.isCancel;
elkAxios.isAxiosError = Axios.isAxiosError;

let isVersionUpdating = false;
let isRefreshUpdating = false;
let refreshTrack = [];
let isLocaleUpdating = false;

const showModalMessageOfVersions = () => {
  return new Promise(() => {
    MessageBox.alert(
      I18N.t(getCommonCaptionByName('version_updateAlertDescription')),
      I18N.t(getCommonCaptionByName('version_updateAlertTitle')),

      {
        showClose: false,
        callback: action => {
          if (action === ALERT_CONFIRM_ACTION) {
            return location.reload(true);
          }
        }
      }
    );
  });
};

const processRefreshTrask = (error, token = null) => {
  refreshTrack.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  refreshTrack = [];
};

elkAxios.interceptors.request.use(checkMobileViewTokenInterceptors);

elkAxios.interceptors.request.use(
  response => {
    const token = localStorageService.getToken();
    const localeId = store.state.locale.localeId;

    if (token) {
      response.headers['Authorization'] = 'Bearer ' + token;
    } else {
      delete elkAxios.defaults.headers.common['Authorization'];
    }

    if (localeId) {
      response.headers['Locale'] = localeId;
      response.headers['Dpo.Locale'] = getLocaleName(localeId);
    }

    if (
      response.url.includes('/dpo/rest/') ||
      response.url.includes('/mdm-gate/') ||
      response.url.includes('/payment-gate/')
    ) {
      response.baseURL = '';
    }

    return response;
  },
  error => {
    //обработать случай, когда юзер не подключен к интернете вовсе
    Promise.reject(error);
  }
);

elkAxios.interceptors.response.use(
  async response => {
    const responseLocaleId = response.headers.locale;
    const frontendVersion = response?.headers['frontend-version'];
    const currentLocaleId = store.state.locale.localeId;

    if (frontendVersion && isFrontendVersion(frontendVersion)) {
      if (isFrontendVersionOld(store.state.version, frontendVersion)) {
        if (!isVersionUpdating) {
          isVersionUpdating = true;
          await showModalMessageOfVersions();
        } else {
          return new Promise(() => {});
        }
      }
    }

    if (
      !isLocaleUpdating &&
      response.config.url !== SET_LOCALE &&
      responseLocaleId &&
      currentLocaleId != responseLocaleId
    ) {
      isLocaleUpdating = true;

      store
        .dispatch(`locale/${SET_LOCALE_ACTION}`, responseLocaleId)
        .then(() => {
          isLocaleUpdating = false;
        });
    }
    return response;
  },
  async error => {
    if (elkAxios.isCancel(error)) {
      return Promise.reject(error);
    }
    const originalRequestUrl = error.config.url;

    if (error.response) {
      const originalRequest = error.config;
      const frontendVersion = error.response.headers?.['frontend-version'];

      if (frontendVersion && isFrontendVersion(frontendVersion)) {
        if (isFrontendVersionOld(store.state.version, frontendVersion)) {
          if (!isVersionUpdating) {
            isVersionUpdating = true;
            await showModalMessageOfVersions();
          } else {
            return new Promise(() => {});
          }
        }
      }

      if (
        error.response.status === 500 &&
        originalRequestUrl !== GET_NOTIFICATION_COUNT
      ) {
        setNotification({
          message: I18N.t(getCommonCaptionByName('error_500'))
        });
        error.isHandled = true;
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        if (
          originalRequest.headers.Authorization !==
          'Bearer ' + localStorageService.getToken()
        ) {
          originalRequest.headers['Authorization'] =
            'Bearer ' + localStorageService.getToken();
          return Promise.resolve(elkAxios(originalRequest));
        }

        if (error.response.headers['is-active'] === 'false') {
          router.push({path: 'error', query: {code: 'U05'}});
        } else if (error.response.headers['access-token'] === 'expired') {
          if (isRefreshUpdating) {
            return new Promise(function (resolve, reject) {
              refreshTrack.push({resolve, reject});
            })
              .then(() => {
                return elkAxios(originalRequest);
              })
              .catch(err => {
                return Promise.reject(err);
              });
          }

          isRefreshUpdating = true;
          return new Promise((resolve, reject) => {
            elkAxios
              .get('refresh-token')
              .then(res => {
                if (res && res.headers && res.headers['set-access-token']) {
                  const newToken = res.headers['set-access-token'];
                  localStorageService.setToken(newToken);
                  elkAxios.defaults.headers.common['Authorization'] =
                    'Bearer ' + newToken;
                  isRefreshUpdating = false;

                  processRefreshTrask(null, newToken);
                  resolve(elkAxios(originalRequest));
                } else {
                  //sentry
                  throw new Error();
                }
              })
              .catch(err => {
                processRefreshTrask(err, null);
                return reject(err);
              });
          }).then(data => {
            isRefreshUpdating = false;
            return data;
          });
        } else {
          return logOut();
        }
      }

      return Promise.reject(error);
    } else if (error.request) {
      if (
        error.isAxiosError &&
        error.code === 'ECONNABORTED' &&
        originalRequestUrl !== GET_NOTIFICATION_COUNT
      ) {
        setNotification({
          message: I18N.t(getCommonCaptionByName('error_requestTimeout'))
        });
        error.isHandled = true;
        return Promise.reject(error);
      }

      if (
        error.message === 'Network Error' &&
        originalRequestUrl !== GET_NOTIFICATION_COUNT
      ) {
        setNotification({
          message: I18N.t(
            getCommonCaptionByName('error_lowNetworkConnectivity')
          )
        });
        error.isHandled = true;
        return Promise.reject(error);
      }

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default elkAxios;
